.navbar,
.navbar-collapse {
    background-color: #0f0f0f;
    opacity: 0.95;
}

.nav-item {
    padding-left: 10px;
    padding-right: 10px;
}

    .nav-item > a {
        text-decoration: none;
        color: white;
    }

        .nav-item > a:hover {
            color: #bf0023;
        }

.navbar {
    padding-right: 16.66%;
    padding-left: 16.66%;
    height: 80px;
}

@media only screen and (max-width: 768px) {
    .navbar {
        padding-right: 10%;
        padding-left: 10%;
        height: 50px;
    }
}


/*.logo, .navbar-brand > img {
    max-height: 80px;
}*/
.navbar-brand {
    height: 100%;
    display: block;
    float: none;
    padding: 0px;
}

.navbar-brand > a > img {
    display: block;
    max-height: 100%;
}

#lblCartCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px;
}

.badge {
    padding-left: 9px;
    padding-right: 9px;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    border-radius: 9px;
}

.fa-shopping-cart{
    font-size:1.5em;
}