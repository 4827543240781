body:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background-image: url('./tlo.jpg');
    background-size: cover;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
}

.centering {
    -webkit-justify-content: center !important;
    justify-content: center !important;
    display: -webkit-flex !important;
    display: flex !important;
}

.btn-red {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    border-width: 3px;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #bf0023;
    border-color: #bf0023;
}

    .btn-red:focus, .btn-red:hover {
        color: #bf0023
    }

    .btn-red:focus,
    .btn-red:hover {
        background-color: inherit;
        opacity: .9;
    }

.btn-red {
    border-radius: 50px 50px 50px 50px;
    padding: 12px 40px 12px 40px;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.btn-red-outline {
    border-radius: 50px 50px 50px 50px;
    padding: 12px 40px 12px 40px;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    border-width: 3px;
    text-transform: uppercase;
    color: #bf0023;
    background-color: white;
    border-color: #bf0023;
}

    .btn-red-outline:focus,
    .btn-red-outline:hover {
        background-color: #bf0023;
        opacity: .9;
        color: white
    }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    font-size: 14px;
    font-weight: bold;
    color: white;
    letter-spacing: 1px;
    border-width: 1px;
    text-transform: uppercase;
    background-color: #bf0023;
    border-color: #bf0023 #bf0023 #bf0023;
}

.nav-tabs .nav-link {
    font-size: 14px;
    font-weight: bold;
    color: #bf0023;
    letter-spacing: 1px;
    text-transform: uppercase;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs {
    border-bottom: 1px solid #bf0023;
}

    .nav-tabs .nav-link:hover {
        border-width: 1px;
        border-color: #bf0023 #bf0023 #f5f5f5;
    }

.page-item.active .page-link {
    background-color: #bf0023;
    border-color: #bf0023;
}

.page-link {
    color: #bf0023;
}

.card-body {
    padding-top: 0;
    padding-bottom: 0;
}

.card {
    margin: 3px 0 3px 0;
}

.toggle-card-header {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    border-width: 3px;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
}

    .toggle-card-header:hover {
        background-color: #bf0023;
        color: #ffffff;
    }

.toggle-card-body {
    padding: 13px 0 13px;
}

.star-checked {
    color: orange;
}

.rating {
    display: inline-flex
}

.align-me {
    display: flex;
    align-items: center;
}

.image-gallery {
    width: 100%;
    height: auto;
}

.image-gallery-slide {
    background-color: whitesmoke;
}

.cart-image {
    min-width: 150px;
    width: 150px;
    height: 150px;
    max-height: 150px;
    object-fit: cover;
}

@media only screen and (max-width: 768px) {
    .cart-image {
        min-width: 80px;
        width: 80px;
        height: 80px;
        max-height: 80px;
        object-fit: cover;
    }

    .part-details {
        text-align: center
    }

    table.center {
        margin-left: auto !important;
        margin-right: auto !important;
    }
}


.image-gallery-slide img {
    width: 100%;
    height: auto;
    min-height: 50vh;
    max-height: 50vh;
    object-fit: scale-down;
    overflow: hidden;
    object-position: center center;
}

.fullscreen .image-gallery-slide img {
    max-height: 100vh;
}

.card-img-top {
    width: 100%;
    height: 250px;
    max-height: 250px;
    object-fit: cover;
}

.col {
    word-wrap: break-word;
}

@import "../node_modules/react-image-gallery/styles/css/image-gallery.css";

.fa-trash:hover {
    cursor: pointer;
}

.undecorated:hover {
    text-decoration: none;
    color: #bf0023
}

.undecorated {
    color: initial;
}

.count-input {
    min-width: 50px;
    max-width: 100px;
}

.delete-button {
    position: relative;
    top: 25px;
    cursor: pointer;
}

.delete-stack {
    vertical-align: top;
}

.gallery-image {
    display: inline-block;
    max-width: 20%
}

.react-photo-gallery--gallery > div > img {
    object-fit: cover;
}

.group-select-row {
    cursor: pointer;
}

.table > td {
    border-top: 0px;
}

.table-group th, .table-group td {
    border: 0px !important;
}

.small-font{
    font-size:small;
}
