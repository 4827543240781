.layout {
    /*background-color: slategray;*/
    height: 100vh;

}

.layout-main {
    background-color: whitesmoke;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175);
    border-radius: .25rem;
    padding: 10px;
    margin-bottom: 3rem;
    margin-top: 100px;
}


